import Male from './characters/male.png';
import FeMale from './characters/female.png';
import Oculus from './logo-oculus-1.png';
import Meta_Quest2 from './Meta_Quest2_lockup_mono_white_RGB_90.png';

import Gun_1 from './gun/AK47_thumb.png';
import Gun_2 from './gun/SCAR_thumb.png';
import Gun_3 from './gun/M4A1_thumb.png';
import Gun_4 from './gun/HK416_thumb.png';
import Gun_5 from './gun/XM1014_thumb.png';
import Gun_6 from './gun/UZI_thumb.png';
import Gun_7 from './gun/VECTOR_thumb.png';
import Gun_8 from './gun/GLOCK_thumb.png';

import Akm_Gun from './gun/webp/AK47.webp';
import Scar_Gun from './gun/webp/SCAR.webp';
import M4A1_Gun from './gun/webp/M4A1.webp';
import HK416_Gun from './gun/webp/HK416.webp';
import XM1014_Gun from './gun/webp/XM1014.webp';
import Uzi_Gun from './gun/webp/UZI.webp';
import Vector_Gun from './gun/webp/VECTOR.webp';
import Glock_Gun from './gun/webp/GLOCK.webp';

import Gun_Slide_1 from './gun/AK47.png';
import Gun_Slide_2 from './gun/SCAR.png';
import Gun_Slide_3 from './gun/M4A1.png';
import Gun_Slide_4 from './gun/HK416.png';
import Gun_Slide_5 from './gun/XM1014.png';
import Gun_Slide_6 from './gun/UZI.png';
import Gun_Slide_7 from './gun/VECTOR.png';
import Gun_Slide_8 from './gun/GLOCK.png';

import Gun_Thumb_Webp_AKM from './gun/webp/AK47_thumb.webp';
import Gun_Thumb_Webp_SCAR from './gun/webp/SCAR_thumb.webp';
import Gun_Thumb_Webp_M4A1 from './gun/webp/M4A1_thumb.webp';
import Gun_Thumb_Webp_HK416 from './gun/webp/HK416_thumb.webp';
import Gun_Thumb_Webp_XM1014 from './gun/webp/XM1014_thumb.webp';
import Gun_Thumb_Webp_UZI from './gun/webp/UZI_thumb.webp';
import Gun_Thumb_Webp_VECTOR from './gun/webp/VECTOR_thumb.webp';
import Gun_Thumb_Webp_GLOCK from './gun/webp/GLOCK_thumb.webp';

// Items
import Drone_Webp from './items/webp/drone_slide.webp';
import Hangrao_Webp from './items/webp/hangrao_slide.webp';
import Hangrao_2_Webp from './items/webp/hangrao_slide1.webp';
import Trap_Webp from './items/webp/trap_slide.webp';
import Usung_Webp from './items/webp/usung_slide.webp';

// Items Thumb

import Drone_Thumb_Webp from './items/webp/drone.webp';
import Hangrao_Thumb_Webp from './items/webp/hangrao.webp';
import Hangrao_2_Thumb_Webp from './items/webp/hangrao.webp';
import Trap_Thumb_Webp from './items/webp/trap.webp';
import Usung_Thumb_Webp from './items/webp/usung.webp';


import Thumb from './thum.png';
import GunItemBg from './gun/gun-item-bg.svg';

import Gallery_Img1 from './gallery/1.webp';
import Gallery_Img2 from './gallery/2.webp';
import Gallery_Img3 from './gallery/3.webp';
import Gallery_Img4 from './gallery/4.webp';
import Gallery_Img5 from './gallery/5.webp';
import Gallery_Img6 from './gallery/6.webp';
import Gallery_Img7 from './gallery/7.webp';
import Gallery_Img8 from './gallery/8.webp';

import Background_1 from './backgrounds/bg-1.png';
import Background_2 from './backgrounds/banner-min-old.webp';
import Background_3 from './backgrounds/17-min.webp';
import Background_4 from './backgrounds/1-min.webp';
import Background_5 from './backgrounds/6-min.webp';
import Background_6 from './backgrounds/7-min.webp';
import Background_7 from './backgrounds/8-min.webp';
import Background_8 from './backgrounds/12-min.webp';
import Background_9 from './backgrounds/13-min.webp';
import Background_10 from './backgrounds/16-min.webp';
import Background_video from './backgrounds/bg-video.svg';
import Background_roadmap from './backgrounds/bg-roadmap.png';
import Background_title from './backgrounds/bg-title.png';
import Background_line from './backgrounds/bg-line.png';

// gun icons
import Percent from'./gun/icons/hp.svg';
import Percent_White from './gun/icons/hp-white.svg';
import Percent_Bg from'./gun/icons/hp_bg.svg';
import Damage from './gun/icons/Damage.png';
import Fire_rare from './gun/icons/Fire_rare.png';
import Recoil_control from './gun/icons/Recoil_control.png';
import Accurate from './gun/icons/Rare.png';
import Magazine_size from './gun/icons/Magazine_size.png';
import Force from './gun/icons/Force.png';
import Range from './gun/icons/Range.png';
import Shape_svg  from './shape/shape_svg.svg';
import Shape_svg_1  from './shape/shape_svg_1.svg';
import Shape_svg_2  from './shape/shape_svg_2.svg';
import Shape_svg_3  from './shape/shape_svg_3.svg';
import Shape_svg_4  from './shape/shape_svg_4.svg';
import Shape_svg_5  from './shape/shape_svg_5.svg';

import Pve from './game_play/pve-min.webp';
import Co_op from './game_play/coop-min.webp';
import Pvp from './game_play/pvp.png';

const thumb = Thumb;
const oculus = Oculus;
const meta_quest2 = Meta_Quest2;
const characters = [ Male, FeMale ];
const percent = [Percent, Percent_Bg, Percent_White];
const icons_gun = [Damage, Fire_rare , Recoil_control, Accurate, Magazine_size, Force, Range];
const gun = [
    Gun_1, 
    Gun_2, 
    Gun_3, 
    Gun_4, 
    Gun_5, 
    Gun_6, 
    Gun_7,
    Gun_8
];
const gun_webp = [
    Akm_Gun, 
    Scar_Gun, 
    M4A1_Gun, 
    HK416_Gun, 
    XM1014_Gun, 
    Uzi_Gun, 
    Vector_Gun, 
    Glock_Gun
];
const gun_thumb_webp = [
    Gun_Thumb_Webp_AKM, 
    Gun_Thumb_Webp_SCAR,
    Gun_Thumb_Webp_M4A1, 
    Gun_Thumb_Webp_HK416, 
    Gun_Thumb_Webp_XM1014, 
    Gun_Thumb_Webp_UZI,
    Gun_Thumb_Webp_VECTOR,
    Gun_Thumb_Webp_GLOCK
];
const gunSlide = [
    Gun_Slide_1, 
    Gun_Slide_2, 
    Gun_Slide_3, 
    Gun_Slide_4, 
    Gun_Slide_5, 
    Gun_Slide_6, 
    Gun_Slide_7,
    Gun_Slide_8
];

//Items
const items_webp = [
    Drone_Webp,
    Hangrao_Webp,
    Hangrao_2_Webp,
    Trap_Webp,
    Usung_Webp
];

const items_thumb_webp = [
    Drone_Thumb_Webp,
    Hangrao_Thumb_Webp,
    Hangrao_2_Thumb_Webp,
    Trap_Thumb_Webp,
    Usung_Thumb_Webp
];

const gallery = [
    Gallery_Img1, 
    Gallery_Img2, 
    Gallery_Img3, 
    Gallery_Img4, 
    Gallery_Img5, 
    Gallery_Img6, 
    Gallery_Img7, 
    Gallery_Img8
];

const gunItemBg = GunItemBg;
const backGrounds = [Background_1, Background_2, Background_3, Background_4 ,Background_5, Background_6, Background_7, Background_8, Background_9, Background_10];

const bg_video = Background_video;
const bg_roadmap = Background_roadmap;
const bg_title = Background_title;
const bg_line = Background_line;
const shape_svg = [Shape_svg, Shape_svg_1, Shape_svg_2, Shape_svg_3, Shape_svg_4, Shape_svg_5];
const pve = Pve;
const coop = Co_op;
const pvp = Pvp;

export { characters };
export { gun };
export { thumb };
export { gallery };
export { gunSlide };
export { gunItemBg };
export { backGrounds };
export { gun_webp };
export { gun_thumb_webp };
export { oculus };
export { meta_quest2 };
export { bg_video };
export { bg_roadmap };
export { bg_title };
export { bg_line };
export { items_webp };
export { items_thumb_webp };

export { percent };
export { icons_gun };
export { shape_svg };
export { pve };
export { coop };
export { pvp };


import React from "react";
import {
    Link
} from "react-router-dom";

import { FaTwitter, FaDiscord, FaTelegramPlane } from "react-icons/fa";

function FooterPage() {

    return (
        <div className="copyright-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-12">
                        <div className="copyright-left">
                            <div className="copyright-text">
                                <p>Copyright © 2022 <a href="/" rel="noreferrer">Deadzombies VR - 8K Studio</a> All Rights Reserved.</p>
                            </div>
                            <ul className="list-link-footer">
                                <li>
                                    <Link className="box-img-flex" to="/privacy-policy" rel="noreferrer">
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="list-social">
                            <li>
                                <a className="box-img-flex" href="#" rel="noreferrer" target="_blank">
                                    <FaTwitter />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="#" rel="noreferrer" target="_blank">
                                    <FaDiscord />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="#" rel="noreferrer" target="_blank">
                                    <FaTelegramPlane />
                                </a>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterPage;

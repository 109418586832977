import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import { ParallaxProvider } from 'react-scroll-parallax';

import HeaderPage from "./components/Header/Header";
import FooterPage from "./components/Footer/Footer.js";
import PageGame from "./pages/Game_Main";
import TermConditions from './pages/TermConditions';
import Gallery from "./pages/Gallery";
import MainParallax from "./pages/Main_ Parallax";

function App() {
    return (
        <ParallaxProvider>
            <Router>
                <HeaderPage />
                <div className="main-wrapper">
                    <Switch>
                        <Route exact path="/">
                            <PageGame />
                        </Route>
                        <Route exact path="/privacy-policy">
                            <TermConditions />
                        </Route>
                        <Route exact path="/gallery">
                            <Gallery />
                        </Route>
                        <Route exact path="/main2">
                            <MainParallax />
                        </Route>
                    </Switch>
                </div>
                <FooterPage />
            </Router>
        </ParallaxProvider>
    );
}

export default App;

import React from "react";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { percent } from "../../assets/images/index.js";

function GunItem({item}) {

    return (
        <div className="gun-item latest-games-items mb-60">
            <div className="latest-games-thumb gun-item-slide">
                <LazyLoadImage
                    loading="lazy"
                    alt="DeadzoneZombie VR"
                    src={item.nft_webp}
                    effect="blur"
                />
                <div className="gun-item-slide-name">
                    <h4><span>{item.name}</span></h4>
                    <p>Class : <span>{item.type}</span></p>
                </div>
            </div>
            <div className="latest-games-content gun-content"
            >
                <div className="gun-attribute-wrap">
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.damage.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3 className="gun-attribute-name">Damage</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.damage.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.damage.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            {/* <img src={item.fire_rare.icon} alt="" />  */}
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.fire_rare.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Fire Rate</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.fire_rare.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.fire_rare.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            {/* <img src={item.recoil_control.icon} alt="" />  */}
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.recoil_control.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Recoil Control</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.recoil_control.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.recoil_control.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            {/* <img src={item.accurate.icon} alt="" />  */}
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.accurate.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Accurate</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div
                                        className="gun-attribute-progress"
                                        style={{
                                            width: `${item.accurate.percent_cal}%`,
                                            backgroundImage: `url(${percent[0]})`
                                        }}
                                    ></div>
                                </div>
                                <span className="current">{item.accurate.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            {/* <img src={item.magazine_size.icon} alt="" /> */}
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.magazine_size.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Magazine Size</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.magazine_size.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.magazine_size.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            {/* <img src={item.force.icon} alt="" /> */}
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.force.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Force</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.force.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.force.number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gun-attribute">
                        <div className="gun-attribute-left">
                            <LazyLoadImage
                                loading="lazy"
                                alt="DeadzoneZombie VR"
                                src={item.range.icon}
                                effect="blur"
                            />
                        </div>
                        <div className="gun-attribute-right">
                            <h3>Range</h3>
                            <div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
                                <div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
                                    <div className="gun-attribute-progress" style={{ width: `${item.range.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
                                </div>
                                <span className="current">{item.range.number}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GunItem;

import React, { useState, useEffect, useRef } from "react";
import {
    Link
} from "react-router-dom";

import { 
    FiAlignJustify, 
    FiX,
    FiChevronDown, 
    FiLogOut, 
    FiUser, 
    FiUsers, 
    FiCreditCard  
} from "react-icons/fi";

import { } from "react-icons/fi";


import Logo from '../../assets/images/logo.png';
import './Header.css';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function HeaderPage() {

    const [showMenu, setShowMenu ] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    return (
        <header className="header-style-four">
            <div id="sticky-header" className="header-four-wrap">
                <div className="container custom-container-two">
                    <div className="row">
                        <div className="col-12">
                            <div className="main-menu menu-style-two">
                                <nav>
                                    <div className="logo">
                                        <a href="/"><img src={Logo} alt="Logo" /></a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderPage;

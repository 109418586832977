import React, { useState, useEffect, useRef } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom';


import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'animate.css';
import Fancybox from "./fancybox.js";
import YouTube from "react-youtube";


import { FiPlayCircle } from "react-icons/fi";


// import Fancybox from "./fancybox.js";

import '../assets/css/style.css';
import '../assets/css/responsive.css';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';

import video from '../assets/images/Final.mp4';
import mature from '../assets/images/rating_AZS.png';
import { characters } from "../assets/images/index.js";
import { gun } from "../assets/images/index.js";
import { gallery } from "../assets/images/index.js";
import { gunSlide } from "../assets/images/index.js";
import { shape_svg } from "../assets/images/index.js";
import { backGrounds } from "../assets/images/index.js";
import { gun_webp } from "../assets/images/index.js";
import { gun_thumb_webp } from "../assets/images/index.js";
import { bg_title } from "../assets/images/index.js";
import { bg_line } from "../assets/images/index.js";
import { bg_roadmap } from "../assets/images/index.js";
import { items_thumb_webp } from "../assets/images/index.js";
import { items_webp } from "../assets/images/index.js";
import { percent } from "../assets/images/index.js";
import { icons_gun } from "../assets/images/index.js";
import { pve } from "../assets/images/index.js";
import { coop } from "../assets/images/index.js";
import { pvp } from "../assets/images/index.js";
import { oculus } from "../assets/images/index.js";
import { meta_quest2 } from "../assets/images/index.js";

//component

import GunItem from "../components/Gun/Index.js";


import SwiperCore, { Pagination, Navigation, Thumbs } from 'swiper';
SwiperCore.use([Pagination, Navigation, Thumbs]);

let guns = [
	{
		name: "AK47",
		type: "Assault Rifle",
		thumb: gun[0],
		nft: gunSlide[0],
		nft_webp: gun_webp[0],
		thumb_webp: gun_thumb_webp[0],
		damage: {
			number: 10,
			icon: icons_gun[0],
			percent_cal: 10
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 20,
			icon: icons_gun[2],
			percent_cal: 20
		},
		accurate: {
			number: 60,
			icon: icons_gun[3],
			percent_cal: 60
		},
		magazine_size: {
			number: 30,
			icon: icons_gun[4],
			percent_cal: 30
		},
		force: {
			number: 100,
			icon: icons_gun[5],
			percent_cal: 50
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "Scar-L",
		type: "Assault Rifle",
		thumb: gun[1],
		nft: gunSlide[1],
		nft_webp: gun_webp[1],
		thumb_webp: gun_thumb_webp[1],
		damage: {
			number: 6,
			icon: icons_gun[0],
			percent_cal: 6
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 15,
			icon: icons_gun[2],
			percent_cal: 15
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 25,
			icon: icons_gun[4],
			percent_cal: 25
		},
		force: {
			number: 70,
			icon: icons_gun[5],
			percent_cal: 35
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "M416",
		type: "Assault Rifle",
		thumb: gun[2],
		nft: gunSlide[2],
		nft_webp: gun_webp[2],
		thumb_webp: gun_thumb_webp[2],
		damage: {
			number: 5,
			icon: icons_gun[0],
			percent_cal: 5
		},
		fire_rare: {
			number: 0.15,
			icon: icons_gun[1],
			percent_cal: 15
		},
		recoil_control: {
			number: 17,
			icon: icons_gun[2],
			percent_cal: 17
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 25,
			icon: icons_gun[4],
			percent_cal: 25
		},
		force: {
			number: 70,
			icon: icons_gun[5],
			percent_cal: 35
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "HK416",
		type: "Assault Rifle",
		thumb: gun[3],
		nft: gunSlide[3],
		nft_webp: gun_webp[3],
		thumb_webp: gun_thumb_webp[3],
		damage: {
			number: 7,
			icon: icons_gun[0],
			percent_cal: 7
		},
		fire_rare: {
			number: 0.15,
			icon: icons_gun[1],
			percent_cal: 15
		},
		recoil_control: {
			number: 14,
			icon: icons_gun[2],
			percent_cal: 14
		},
		accurate: {
			number: 80,
			icon: icons_gun[3],
			percent_cal: 80
		},
		magazine_size: {
			number: 35,
			icon: icons_gun[4],
			percent_cal: 35
		},
		force: {
			number: 60,
			icon: icons_gun[5],
			percent_cal: 30
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "XM1014",
		type: "ShotGun",
		thumb: gun[4],
		nft: gunSlide[4],
		nft_webp: gun_webp[4],
		thumb_webp: gun_thumb_webp[4],
		damage: {
			number: 12,
			icon: icons_gun[0],
			percent_cal: 12
		},
		fire_rare: {
			number: 1,
			icon: icons_gun[1],
			percent_cal: 100
		},
		recoil_control: {
			number: 30,
			icon: icons_gun[2],
			percent_cal: 30
		},
		accurate: {
			number: 50,
			icon: icons_gun[3],
			percent_cal: 50
		},
		magazine_size: {
			number: 5,
			icon: icons_gun[4],
			percent_cal: 5
		},
		force: {
			number: 120,
			icon: icons_gun[5],
			percent_cal: 60
		},
		range: {
			number: 30,
			icon: icons_gun[6],
			percent_cal: 30
		}
	},
	{
		name: "Uzi",
		type: "Automatic Gun",
		thumb: gun[5],
		nft: gunSlide[5],
		nft_webp: gun_webp[5],
		thumb_webp: gun_thumb_webp[5],
		damage: {
			number: 5,
			icon: icons_gun[0],
			percent_cal: 5
		},
		fire_rare: {
			number: 0.1,
			icon: icons_gun[1],
			percent_cal: 10
		},
		recoil_control: {
			number: 12,
			icon: icons_gun[2],
			percent_cal: 12
		},
		accurate: {
			number: 75,
			icon: icons_gun[3],
			percent_cal: 75
		},
		magazine_size: {
			number: 40,
			icon: icons_gun[4],
			percent_cal: 40
		},
		force: {
			number: 40,
			icon: icons_gun[5],
			percent_cal: 20
		},
		range: {
			number: 35,
			icon: icons_gun[6],
			percent_cal: 35
		}
	},
	{
		name: "Vector",
		type: "Automatic Gun",
		thumb: gun[6],
		nft: gunSlide[6],
		nft_webp: gun_webp[6],
		thumb_webp: gun_thumb_webp[6],
		damage: {
			number: 6,
			icon: icons_gun[0],
			percent_cal: 6
		},
		fire_rare: {
			number: 0.1,
			icon: icons_gun[1],
			percent_cal: 10
		},
		recoil_control: {
			number: 10,
			icon: icons_gun[2],
			percent_cal: 10
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 50,
			icon: icons_gun[4],
			percent_cal: 50
		},
		force: {
			number: 50,
			icon: icons_gun[5],
			percent_cal: 25
		},
		range: {
			number: 35,
			icon: icons_gun[6],
			percent_cal: 35
		}
	},
	{
		name: "Glock",
		type: "Hand Gun",
		thumb: gun[7],
		nft: gunSlide[7],
		nft_webp: gun_webp[7],
		thumb_webp: gun_thumb_webp[7],
		damage: {
			number: 3,
			icon: icons_gun[0],
			percent_cal: 3
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 7,
			icon: icons_gun[2],
			percent_cal: 7
		},
		accurate: {
			number: 80,
			icon: icons_gun[3],
			percent_cal: 80
		},
		magazine_size: {
			number: 10,
			icon: icons_gun[4],
			percent_cal: 10
		},
		force: {
			number: 50,
			icon: icons_gun[5],
			percent_cal: 25
		},
		range: {
			number: 15,
			icon: icons_gun[6],
			percent_cal: 15
		}
	}
]

let items = [
	{
		name: "Drone",
		thumb: items_thumb_webp[0],
		nft: items_webp[0],
		nft_webp: items_webp[0],
		thumb_webp: items_thumb_webp[0],
	},
	{
		name: "Iron Fence",
		thumb: items_thumb_webp[1],
		nft: items_webp[1],
		nft_webp: items_webp[1],
		thumb_webp: items_thumb_webp[1]
	},
	{
		name: "Iron Fence",
		thumb: items_thumb_webp[2],
		nft: items_webp[2],
		nft_webp: items_webp[2],
		thumb_webp: items_thumb_webp[2]
	},
	{
		name: "Trap",
		thumb: items_thumb_webp[3],
		nft: items_webp[3],
		nft_webp: items_webp[3],
		thumb_webp: items_thumb_webp[3]
	},
	{
		name: "Gun Emplacement",
		thumb: items_thumb_webp[4],
		nft: items_webp[4],
		nft_webp: items_webp[4],
		thumb_webp: items_thumb_webp[4]
	}
]

let characters_content = [
	{
		name: 'TOM',
		content: `An elite soldier who was trained for years in DELTA force which is one of Special Mission Units and also the no.1 counterterrorism force of the U.S.
		Troops in this force are physically and mentally trained for years with survival skills under extremely harsh conditions so that they can accomplish their missions.
		Tom has excellently accomplished missions that seem to be almost impossible for normal soldiers: counterterrorism, hostage rescue, direct attack and special reconnaissance, etc. With the ability to use various kinds of weapons competently and special training, Tom can eliminate zombies in a perfect way.		
		`,
		img: characters[0]
	},
	{
		name: 'Taylor',
		content: `An excellent SWAT member who used to have a happy family until her husband and son were attacked and infected by zombies at her house while she was on duty. After the transformation of her beloved ones, the young mother’s heart was filled with hatred for zombies and she decided to fight against them and try to survive in this dark time.`,
		img: characters[1]
	}
]

function MainParallax() {

	const videoOptions = {
		height: '100%',
		width: '100%',
		playerVars: {
			playlist:"5T_m7qdP5S4",
			allowFullScreen: 0,
		  	autoplay: 1,
			loop: 1,
			controls: 0,
			rel: 0,
			showInfo: 0,
			mute: 1,
			playsInline: 0
		}
	};

	const [lightboxOpen, setlightboxOpen] = useState(false);

    const target = useRef(null);

    const location = useLocation();
    const parallaxController = useParallaxController();

    useEffect(() => {
		parallaxController.update();
	}, [location.pathname]);

	return (
		<>
			<main className="main-wrapper">
				<section className="slider-area slider-bg">
					<div className="banner-position">
						<div id="background-video">
							<YouTube className="deadzone-banner" videoId="5T_m7qdP5S4" opts={videoOptions} />
						</div>
					</div>
					<div className="slider-content text-center">
						<h2 className="tlt">
							DeadzoneZombie
							<span> VR</span>
						</h2>
						<p className="">
							DeadzoneZombie VR is a survival game which comprises extreme shooting actions, post-apocalyptic horror elements in combination with VR technology. Events in-game happen during the pandemic crisis in the future which are designed based on Virtual Reality (VR) technology and played by Oculus Quest 2 equipment promising to bring players the most lively fighting experience via the VR glasses. Players can travel in a large open world with arms, use their skills to survive, collect modern weapons to eliminate zombie legions, and stay alive as long as they can.
						</p>
						<div className="vr-warp">
							<a href="https://www.oculus.com/experiences/quest/8235038329902839/" target="_blank"><img className="slider-content-img" src={meta_quest2} alt="" /></a>
						</div>
					</div>
				</section>
                
                    <section id="about-us" className="gamestory-wrap third-about-bg pt-120 pb-90" ref={target}>
                        <div className="container custom-container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
									<Parallax translateX={[210,0, 'easeInOut']} scale={[0,1]} startScroll={0} endScroll={1000} targetElement={target.current}>
                                        <div className="third-about-img text-right position-relative">
                                            <div className="video" style={{ borderImage: `url(${bg_line}) 20` }}>
                                                <div data-type="youtube" data-id="5T_m7qdP5S4" data-width="800" data-height="300" className="lightbox">
                                                    {/* <img src={backGrounds[2]} alt="" /> */}
                                                    <LazyLoadImage
                                                        loading="lazy"
                                                        alt="DeadzoneZombie VR"
                                                        src={backGrounds[9]}
                                                        effect="blur"
                                                    />
                                                    <span className="play" onClick={() => setlightboxOpen(!lightboxOpen)}>
                                                        <FiPlayCircle size={48} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Parallax>
									<div className="third-about-content mt-5">
										<Parallax scale={[1,0]} opacity={[1,0]} startScroll={1500} endScroll={2500} targetElement={target.current}>
											<div className="third-title-style">
												<h2>Game Story</h2>

												<div className="inner">
													<p>
														Events in game happen in 2050, after world war 3, humans face a deadly pandemic called “white flu” which damages victims’ health, makes them uncontrolled, causes mutations for body cells, transforms them into bloodthirsty zombies, spreading horror over the world. After the first infection, the pandemic spread quickly and became an obsession for all of humanity, the world was engulfed in destruction and misery. 02 main characters: Tom and Taylor are the last survivors who will start a journey to destroy zombies and bring life back to the planet.
													</p>
												</div>
											</div>
										</Parallax>
									</div>
                                </div>
                            </div>
                        </div>
                    </section>
               
				<section
					className="third-about-bg pt-90 pb-90 characters-wrapper"
					style={{ backgroundImage: `url(${backGrounds[7]})` }}
				>
					<div className="characters-overlay"></div>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								<Parallax scale={[0, 1]} startScroll={1500} endScroll={2000}>
									<div className="third-title-style text-center">
										<h2>Characters</h2>
									</div>
								</Parallax>
							</div>
							<div className="col-12">
								<div className="character-list">
                                    <div className="character-list-item">
                                        <Parallax translateX={[-100,0]} scale={[0,1]} startScroll={0} endScroll={2000}>
                                            <div className="character-item-wrap" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
                                                <div className="character-item-left">
                                                    <img alt="" src={characters_content[0].img} />
                                                </div>
                                                <div className="character-item-right">
                                                    <h4 className="text-uppercase">{characters_content[0].name}</h4>
                                                    <p>
                                                        {characters_content[0].content}
                                                    </p>
                                                </div>
                                            </div>
                                        </Parallax>
                                    </div>
                                    <div className="character-list-item">
                                        <Parallax translateX={[100,0]} scale={[0,1]} startScroll={0} endScroll={2500}>
                                            <div className="character-item-wrap" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
                                                <div className="character-item-left">
                                                    <img alt="" src={characters_content[1].img} />
                                                </div>
                                                <div className="character-item-right">
                                                    <h4 className="text-uppercase">{characters_content[1].name}</h4>
                                                    <p>
                                                        {characters_content[1].content}
                                                    </p>
                                                </div>
                                            </div>
                                        </Parallax>
                                    </div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="nft" className="third-about-bg nft-wrap pt-90 pb-90">
					<div className="container custom-container">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<Parallax translateX={[-100,0]} scale={[0,1]} startScroll={1000} endScroll={3200}>
									<div className="third-title-style text-left">
										<h2>GUNS</h2>
										<p>Guns, Ammunition, and Items in the game will be designed on the BSC platform with top-notch graphics to create the most real feeling for players when participating in the game.</p>
									</div>
								</Parallax>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-12">
								<div className="gun-slider">
									<Parallax translateX={[-210,0]} opacity={[0,1]} startScroll={1000} endScroll={3600}>
										<GunItem item={guns[0]} />
									</Parallax>
									<Parallax translateX={[210,0]} startScroll={1000} endScroll={4200}>
										<GunItem item={guns[1]} />
									</Parallax>
									<Parallax translateX={[-210,0]} startScroll={1000} endScroll={4800}>
										<GunItem item={guns[2]} />
									</Parallax>
									<Parallax translateX={[210,0]} startScroll={1000} endScroll={5200}>
										<GunItem item={guns[3]} />
									</Parallax>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="third-about-bg items-wrap pt-90 pb-90">
					<div className="container custom-container">
						<div className="row pb-40">
							<div className="col-lg-12 col-md-12 col-12">
								<div className="third-title-style text-center">
									<h2>Items</h2>
								</div>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-12">
								<div className="items_wrap">
									<Parallax translateX={[-210,0]} opacity={[0,1]} startScroll={3000} endScroll={6000}>
										<div className="nft-item latest-games-items">
											<a className="latest-item-img" data-fancybox="gallery-items" href={items[0].nft_webp}>
												<LazyLoadImage
													loading="lazy"
													alt="DeadzoneZombie VR"
													src={items[0].nft_webp}
													effect="blur"
												/>
												<h4 className="item-thumb-name">{items[0].name}</h4>
											</a>
										</div>
									</Parallax>
									<Parallax translateX={[210,0]} opacity={[0,1]} startScroll={3000} endScroll={6000}>
										<div className="nft-item latest-games-items">
											<a className="latest-item-img" data-fancybox="gallery-items" href={items[1].nft_webp}>
												<LazyLoadImage
													loading="lazy"
													alt="DeadzoneZombie VR"
													src={items[1].nft_webp}
													effect="blur"
												/>
												<h4 className="item-thumb-name">{items[1].name}</h4>
											</a>
										</div>
									</Parallax>
									<Parallax translateX={[-210,0]} opacity={[0,1]} startScroll={3000} endScroll={6400}>
										<div className="nft-item latest-games-items">
											<a className="latest-item-img" data-fancybox="gallery-items" href={items[2].nft_webp}>
												<LazyLoadImage
													loading="lazy"
													alt="DeadzoneZombie VR"
													src={items[2].nft_webp}
													effect="blur"
												/>
												<h4 className="item-thumb-name">{items[2].name}</h4>
											</a>
										</div>
									</Parallax>
									<Parallax translateX={[210,0]} opacity={[0,1]} startScroll={3000} endScroll={6400}>
										<div className="nft-item latest-games-items">
											<a className="latest-item-img" data-fancybox="gallery-items" href={items[3].nft_webp}>
												<LazyLoadImage
													loading="lazy"
													alt="DeadzoneZombie VR"
													src={items[3].nft_webp}
													effect="blur"
												/>
												<h4 className="item-thumb-name">{items[3].name}</h4>
											</a>
										</div>
									</Parallax>
									<Parallax translateX={[-210,0]} opacity={[0,1]} startScroll={3000} endScroll={6800}>
										<div className="nft-item latest-games-items">
											<a className="latest-item-img" data-fancybox="gallery-items" href={items[4].nft_webp}>
												<LazyLoadImage
													loading="lazy"
													alt="DeadzoneZombie VR"
													src={items[4].nft_webp}
													effect="blur"
												/>
												<h4 className="item-thumb-name">{items[4].name}</h4>
											</a>
										</div>
									</Parallax>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section
					id="game-play"
					className="features-wrap third-about-bg pt-90 pb-90"
					style={{ backgroundImage: `url(${backGrounds[2]})` }}
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-6 order-0 order-lg-2">
								<div className="third-about-img text-right position-relative">

								</div>
							</div>
							<div className="col-lg-8 offset-lg-2 col-12 pb-40">
									<div className="third-title-style text-center">
										<Parallax scale={[0, 1]} startScroll={6000} endScroll={7200}>
											<h2>Game <span>play</span></h2>
											<p>Various game modes and non-stop battles, build your character and enjoy victorious moments</p>
										</Parallax>
									</div>
							</div>
							<div className="col-12">
								<div className="features_list">
									<Parallax scale={[0,1]} startScroll={5000} endScroll={7800}>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="DeadzoneZombie VR"
														src={pve}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>Large-scale PVE Campaign</h4>
												<p>Players will fight against and eliminate zombies, trying to survive in short missions or take part in the whole series of missions to make achievements on the leaderboard. </p>
											</div>
										</div>
									</Parallax>
									<Parallax scale={[0,1]} startScroll={5000} endScroll={7800}>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="DeadzoneZombie VR"
														src={coop}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>Co-op - Multiplayer</h4>
												<p>Team up with friends for cooperative or antagonistic combats and try to survive waves of increasingly difficult Zombie attacks.</p>
											</div>
										</div>
									</Parallax>
									<Parallax scale={[0,1]} startScroll={5000} endScroll={7800}>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="DeadzoneZombie VR"
														src={pvp}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>PVP ( Update Soon )</h4>
												<p>Players can make a team to fight against other teams to get rewards.</p>
											</div>
										</div>
									</Parallax>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="third-about-bg pt-90 pb-90">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<Parallax scale={[0, 1]} startScroll={7200} endScroll={8200}>
										<h2>BEHIND THE SCENES</h2>
									</Parallax>
								</div>
							</div>
							<div className="col-12">
								<div className="gallery">
									<Fancybox>
										<Parallax translateX={[-210,0]} startScroll={7200} endScroll={8400}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[0]}>
												<img src={gallery[0]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[210,0]} startScroll={7200} endScroll={8400}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[1]}>
												<img src={gallery[1]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[-210,0]} startScroll={7200} endScroll={8700}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[2]}>
												<img src={gallery[2]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[210,0]} startScroll={7200} endScroll={8700}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[3]}>
												<img src={gallery[3]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[-210,0]} startScroll={7200} endScroll={9200}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[4]}>
												<img src={gallery[4]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[210,0]} startScroll={7200} endScroll={9200}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[5]}>
												<img src={gallery[5]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[-210,0]} startScroll={7200} endScroll={9800}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[6]}>
												<img src={gallery[6]} alt="" />
											</a>
										</Parallax>
										<Parallax translateX={[210,0]} startScroll={7200} endScroll={9800}>
											<a className="gallery-item gallery-item-hover" data-fancybox="gallery" href={gallery[7]}>
												<img src={gallery[7]} alt="" />
											</a>
										</Parallax>
									</Fancybox>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="about-us-page pt-90 pb-90" style={{ backgroundImage: `url(${backGrounds[6]})` }}>
					<div className="custom-container container">
						<div className="row">
							<div className="col-12">
								<div className="third-title-style text-center">
									<Parallax scale={[0,1]} startScroll={9000} endScroll={10200}>
										<h2>Videos</h2>
									</Parallax>
								</div>
								<div className="video-list mt-5">
									<Parallax translateX={[-210,0]} scale={[0,1]} startScroll={8000} endScroll={10300}>
										<div className="video-item">
											<div className="video-top">
												<iframe width="100%" height="300" src="https://www.youtube.com/embed/Z01VcHkqgdE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
											</div>
											<div className="video-bottom">
												<h3>#walkthrough #gamevr #oculusquest2 #deadzoneZombie</h3>
											</div>
										</div>
									</Parallax>
									<Parallax translateX={[210,0]} scale={[0,1]} startScroll={8000} endScroll={10300}>
										<div className="video-item">
											<div className="video-top">
												<iframe width="100%" height="300" src="https://www.youtube.com/embed/7MDQLvl2qWg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
											</div>
											<div className="video-bottom">
												<h3>#walkthrough #gamevr #oculusquest2 #deadzoneZombie</h3>
											</div>
										</div>
									</Parallax>
									<Parallax translateX={[-210,0]}scale={[0,1]} startScroll={8000} endScroll={10800}>
										<div className="video-item">
											<div className="video-top">
												<iframe width="100%" height="300" src="https://www.youtube.com/embed/hMeaMKau8js" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
											</div>
											<div className="video-bottom">
												<h3>#walkthrough #gamevr #oculusquest2 #deadzoneZombie</h3>
											</div>
										</div>
									</Parallax>
									<Parallax translateX={[210,0]}scale={[0,1]} startScroll={8000} endScroll={10800}>
										<div className="video-item">
											<div className="video-top">
												<iframe width="100%" height="300" src="https://www.youtube.com/embed/OfPXVcJ3v8o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
											</div>
											<div className="video-bottom">
												<h3>#walkthrough #gamevr #oculusquest2 #deadzoneZombie</h3>
											</div>
										</div>
									</Parallax>
									<Parallax translateX={[-210,0]}scale={[0,1]} startScroll={8000} endScroll={11300}>
										<div className="video-item">
											<div className="video-top">
												<iframe width="100%" height="300" src="https://www.youtube.com/embed/Su8YHV2WuCE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                                    </div>
											<div className="video-bottom">
												<h3>#walkthrough #gamevr #oculusquest2 #deadzoneZombie</h3>
											</div>
										</div>
									</Parallax>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="third-about-bg pt-90 pb-90">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<h2 className="mb-5 d-block">RATED M FOR MATURE</h2>
									<LazyLoadImage
										loading="lazy"
										alt=""
										src={mature}
										effect="blur"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>

			{lightboxOpen && (
				<div className="video-lightbox">
					<ReactImageVideoLightbox
						data={[
							{
								url: 'https://www.youtube.com/embed/5T_m7qdP5S4',
								type: "video",
								title: "DeadzoneZombie VR | Gameplay Demo",
							}
						]}
						startIndex={0}
						showResourceCount={true}
						onCloseCallback={() => setlightboxOpen(!lightboxOpen)}
					/>
				</div>
			)}
		</>
	);
}

export default MainParallax;

import React from "react";

import './Page_Title.css';

import { 
    FiChevronRight 
} from "react-icons/fi";

import shape from '../../assets/images/ink.png';

function PageTitle({...props}) {

    return (
        <div className="page-title-wrap" style={{ backgroundImage: `url(${props.background})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{props.title}</h1>
                    </div>
                    {
                        props.breadcrumb 
                        ? 
                        <div className="col-12">
                            <ul className="breadcrumb-list">
                                <li className="item"><a href="/">Home</a></li>
                                <li className="separator"><FiChevronRight/></li>
                                <li className="item current">{props.breadcrumb}</li>
                            </ul>
                        </div>
                        : 
                        ''
                    }
                </div>
            </div>
            <div className="page-shape" style={{ backgroundImage: `url(${shape})` }}></div>
        </div>
    );
}

export default PageTitle;
